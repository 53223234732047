import React from 'react';
import { Box } from '@heycater/design-system';
import Image, { ImageProps } from 'next/image';
import styled from 'styled-components';

import { useBreakpointUp } from 'qualification/hooks/useBreakpoints';

type Props = {
  variant?: 'responsive' | 'compact';
} & ImageProps;

const ImageWithOverlayAndChatBubble = ({
  sizes,
  height,
  width,
  variant,
  ...props
}: Props) => {
  const smUp = useBreakpointUp('sm');
  const isLarge = variant === 'compact' ? false : smUp;

  return (
    <>
      <BackgroundEllipseImage $isLarge={isLarge}>
        <Image
          alt=""
          src="/artifacts/images/shared/contact/background-ellipse.webp"
          height={height}
          width={width}
          sizes={sizes}
        />
      </BackgroundEllipseImage>

      <Box
        position="relative"
        borderRadius="500px"
        overflow="hidden"
        width={width}
      >
        <Image alt="" height={height} width={width} {...props} sizes={sizes} />
      </Box>
      <Box
        position="absolute"
        right={isLarge ? 3 : undefined}
        left={isLarge ? undefined : -15}
        bottom={isLarge ? 0 : 5}
        zIndex={3}
        height={isLarge ? 50 : 35}
        width={isLarge ? 50 : 35}
      >
        <Image
          height={isLarge ? 50 : 35}
          alt=""
          width={isLarge ? 50 : 35}
          src="/artifacts/images/shared/contact/green-chat-bubble.webp"
          layout="responsive"
        />
      </Box>
    </>
  );
};

const BackgroundEllipseImage = styled.div<{ $isLarge: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: ${({ $isLarge }) =>
    $isLarge
      ? 'translateX(-3%) translateY(6%) scaleX(1.1) scaleY(1.07)'
      : 'translateX(-5%) translateY(2%) scaleX(1.15) scaleY(1.07)'};
  rotate: 10deg;
`;

export default ImageWithOverlayAndChatBubble;
