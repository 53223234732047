import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { BoxProps } from '@material-ui/core';
import Link from 'next/link';
import styled from 'styled-components';

import { useBreakpointUp } from 'qualification/hooks/useBreakpoints';
import FlexBox from 'shared/components/FlexBox';
import ImageWithOverlayAndChatBubble from 'shared/components/ImageWithOverlayAndChatBubble';
import { createSizes } from 'shared/helpers/createSizes';
import DarkButton from 'system/components/Button/DarkButton';

interface Props extends BoxProps {
  imageSrc: string;
  name: string;
  description: string;
  title: string;
  ctaLink: string;
  ctaLabel: string;
  backgroundColor?: string;
}

export default function ExpertSection({
  imageSrc,
  title,
  name,
  description,
  ctaLink,
  ctaLabel,
  ...boxProps
}: Props) {
  const smUp = useBreakpointUp('sm');

  return (
    <WrapperBox bgcolor="#f5f6ea" {...boxProps}>
      <ImageWrapperBox position="relative" mb={3} mt={1}>
        <ImageWithOverlayAndChatBubble
          src={imageSrc}
          width={smUp ? 180 : 105}
          height={smUp ? 180 : 105}
          objectFit="cover"
          sizes={createSizes({ xs: '120px', sm: '200px' })}
          alt=""
        />
      </ImageWrapperBox>
      <TitleWrapperBox mb={{ xs: 2.2, sm: 0 }}>
        <Title component="h2" variant="h5">
          {title}
        </Title>
      </TitleWrapperBox>

      <DescriptionWrapperBox mb={{ xs: 2.2, sm: 0 }}>
        <Text>{name}</Text>

        <Text>{description}</Text>
      </DescriptionWrapperBox>

      <CTAWrapperBox>
        <FlexBox justifyContent={{ xs: 'center', sm: 'flex-start' }}>
          <Link href={ctaLink} passHref>
            <DarkButton component="a" rounded target="_blank">
              {ctaLabel}
            </DarkButton>
          </Link>
        </FlexBox>
      </CTAWrapperBox>
    </WrapperBox>
  );
}

const ImageWrapperBox = styled(Box)`
  grid-area: image;
  width: fit-content;
  justify-self: center;
`;

const DescriptionWrapperBox = styled(Box)`
  grid-area: description;
`;

const TitleWrapperBox = styled(Box)`
  grid-area: title;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: ${({ theme }) => theme.spacing(2)}px;
  }
`;

const CTAWrapperBox = styled(Box)`
  grid-area: cta;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`;

const WrapperBox = styled(Box)`
  display: grid;
  grid-template-areas:
    'image'
    'description'
    'title'
    'cta';
  grid-template-columns: 1fr;

  align-items: center;
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(1, 3, 4, 3)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(1, 5, 1, 6)};

    column-gap: ${({ theme }) => theme.spacing(6)}px;
    row-gap: ${({ theme }) => theme.spacing(2)}px;
    grid-template-areas:
      'image title'
      'image description'
      'image cta';
    grid-template-columns: auto 1fr;
  }
`;

const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: ${({ theme }) => theme.palette.secondary['400']};
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 20px;
    text-align: start;
    color: ${({ theme }) => theme.palette.secondary['500']};
  }
`;

const Text = styled(Typography)`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 16px;
    line-height: 24px;
    text-align: start;
  }
`;
